import React, { useId, useState } from 'react'
import classNames from 'classnames'
import type { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button, CategoryCard, ProductCard, ProjectCard } from '~/components'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import styles from './Cards.module.css'
import { ICards, CardTypes } from './Cards.types'
import 'swiper/css'
import 'swiper/css/scrollbar'

const CardSwiper = ({ isCardTypeCategoryCard, cards }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const swiperId = useId().replace(/\/|\s|\:/gm, '_')
  return (
    <div
      className={classNames(
        {
          [styles.categoryCardSwiperRoot]: isCardTypeCategoryCard,
          [styles.projectCardSwiperRoot]: !isCardTypeCategoryCard,
        },
        'flex relative justify-center'
      )}>
      <Swiper
        spaceBetween={16}
        modules={[Controller, Navigation, Scrollbar, A11y]}
        controller={{ control: swiper }}
        onSwiper={setSwiper}
        slidesPerView='auto'
        centerInsufficientSlides
        scrollbar={{
          draggable: true,
          el: `.${swiperId}`,
          dragClass: styles.draggableElement,
        }}>
        {cards?.map((card, i) => {
          return (
            <SwiperSlide key={i} className='!h-auto'>
              {card.cardType === CardTypes.CATEGORY && (
                <CategoryCard {...card} key={i} />
              )}
              {card.cardType === CardTypes.PRODUCT && (
                <ProductCard {...card} key={i} />
              )}
              {card.cardType === CardTypes.PROJECT && (
                <ProjectCard {...card} key={i} />
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div
        className={classNames(
          'swiper-scrollbar !-bottom-6 !h-[2px]',
          swiperId,
          styles.scrollbarContainer
        )}
      />
    </div>
  )
}

export const Cards = ({
  title,
  paragraph,
  seeMoreText,
  seeMoreUrl,
  cards,
  backgroundColor,
  width,
  image,
  isDesktopCarouselDisabled,
  isMobileCarouselDisabled,
  rowItemCountOnDesktop,
  rowItemCountOnMobile,
  categorySku,
}: ICards) => {
  const isCardTypeCategoryCard = cards?.[0]?.cardType === CardTypes.CATEGORY
  const isCartTypeProductCard = cards?.[0]?.cardType === CardTypes.PRODUCT
  const isCartTypeProjectCard = cards?.[0]?.cardType === CardTypes.PROJECT
  const imageContainerClassName = image?.src
    ? `row-span-full px-4 py-8 lg:self-center lg:pl-12 lg:pr-20 lg:py-16 2xl:p-20 ${styles.cardsContainer}`
    : 'py-8 md:py-20 grid-col'

  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames(
        backgroundColor === 'dark' ? 'bg-background-thumb' : 'bg-white',
        styles.root,
        isCartTypeProductCard && '!py-8 md:!py-10 md:!px-20',
        isCartTypeProjectCard && '!py-12 md:!p-20'
      )}
      innerClassName={classNames(image?.src ? styles.wrapperContainer : '')}>
      {image && (
        <div className='hidden lg:block relative w-full lg:row-span-full lg:h-full'>
          {image?.src && (
            <Image
              className='object-fill'
              src={getImageSrc(image?.src, '656')}
              addSrcSet={false}
              alt={image?.altText}
              fill
              sizes='656px'
            />
          )}
        </div>
      )}
      <div className={isCardTypeCategoryCard && imageContainerClassName}>
        <div
          className={classNames('relative', {
            [styles.categoryCardHeadingWrapper]: isCardTypeCategoryCard,
            [styles.projectCardHeadingWrapper]: isCartTypeProjectCard,
          })}>
          <div className='w-full flex flex-col items-center gap-2 mb-8 lg:mb-12'>
            <Typography
              tag={TypographyTag.h3}
              variant={TypographyVariant.Heading3}
              className={styles.title}>
              {title}
            </Typography>
            {paragraph && (
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegularExtraLineHeight}
                className={classNames('text-secondary-muted')}>
                {paragraph}
              </Typography>
            )}
          </div>
        </div>
        {cards?.length > 0 && (
          <>
            <div className='hidden md:block'>
              {isDesktopCarouselDisabled ? (
                <div
                  className='grid gap-4'
                  style={{
                    gridTemplateColumns: `repeat(${rowItemCountOnDesktop}, minmax(0, 1fr))`,
                  }}>
                  {cards?.map((card, i) => {
                    return (
                      <React.Fragment key={i}>
                        {card.cardType === CardTypes.CATEGORY && (
                          <CategoryCard
                            {...card}
                            key={i}
                            isDesktopCarouselDisabled={
                              isDesktopCarouselDisabled
                            }
                            categorySku={categorySku}
                          />
                        )}
                        {card.cardType === CardTypes.PRODUCT && (
                          <ProductCard {...card} key={i} />
                        )}
                        {card.cardType === CardTypes.PROJECT && (
                          <ProjectCard {...card} key={i} />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              ) : (
                <CardSwiper
                  isCardTypeCategoryCard={isCardTypeCategoryCard}
                  cards={cards}
                />
              )}
            </div>
            <div className='md:hidden'>
              {isMobileCarouselDisabled ? (
                <div
                  className='grid gap-4'
                  style={{
                    gridTemplateColumns: `repeat(${rowItemCountOnMobile}, minmax(0, 1fr))`,
                  }}>
                  {cards?.map((card, i) => {
                    return (
                      <React.Fragment key={i}>
                        {card.cardType === CardTypes.CATEGORY && (
                          <CategoryCard {...card} key={i} />
                        )}
                        {card.cardType === CardTypes.PRODUCT && (
                          <ProductCard {...card} key={i} />
                        )}
                        {card.cardType === CardTypes.PROJECT && (
                          <ProjectCard
                            {...card}
                            key={i}
                            isMobileCarouselDisabled={isMobileCarouselDisabled}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              ) : (
                <CardSwiper
                  isCardTypeCategoryCard={isCardTypeCategoryCard}
                  cards={cards}
                />
              )}
            </div>
          </>
        )}

        {seeMoreText && seeMoreUrl && (
          <div
            className={classNames(
              'flex justify-center mt-12 lg:mt-10 mx-auto',
              {
                'lg:!mt-6': isCartTypeProductCard,
              }
            )}>
            <Button
              status='secondary'
              href={seeMoreUrl}
              size='xs'
              theme={{ root: classNames(styles.cardBtn, 'mx-4') }}>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodyRegularBold}
                className='normal-case text-primary-heading'>
                {seeMoreText}
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </SectionContainer>
  )
}
